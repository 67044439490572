const MS_PER_HOUR = 1000 * 60 * 60;

type TimeSlot = {
  label: string;
  time: number;
};

const inHourSlots = [0, 0.25, 0.5, 0.75];
const getHoursForTime = (hour: number): TimeSlot[] => {
  return inHourSlots.reduce((acc, curr) => {
    const miuteLabel = curr === 0 ? "00" : curr * 60;
    const timeSlot: TimeSlot = { label: hour + ":" + miuteLabel, time: (hour + curr) * MS_PER_HOUR };
    return [...acc, timeSlot];
  }, [] as TimeSlot[]);
};

export const timeOptions = [
  ...getHoursForTime(7),
  ...getHoursForTime(8),
  ...getHoursForTime(9),
  ...getHoursForTime(10),
  ...getHoursForTime(11),
  ...getHoursForTime(12),
  ...getHoursForTime(13),
  ...getHoursForTime(14),
  ...getHoursForTime(15),
  ...getHoursForTime(16),
  ...getHoursForTime(17),
  ...getHoursForTime(18),
  ...getHoursForTime(19),
  ...getHoursForTime(20),
  ...getHoursForTime(21),
  ...getHoursForTime(22),
  ...getHoursForTime(23),
];
