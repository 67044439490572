import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "./index";
import { Moment } from "moment";
import axios from "@/common/axios";
import { TimetrackingEntry } from "@/store/interfaces/timetracking-entry.interface";
import { User } from "@/store/interfaces/user.interface";

interface DateRange {
  fromDate: Moment;
  toDate: Moment;
}

export const state = () => ({
  timetrackingEntries: [] as TimetrackingEntry[],
  dateRange: {} as DateRange,
});

export type TimetrackingState = ReturnType<typeof state>;

export const getters: GetterTree<TimetrackingState, RootState> = {
  timetrackingEntriesForUserId: (state) => (userId: number) => state.timetrackingEntries.filter((entry) => entry.userId === userId),
  timetrackingEntriesForUserIdAndProjectId: (state) => (userId: number, projectId: number | undefined) => {
    const filtered = state.timetrackingEntries
      .filter((entry) => entry.userId === userId)
      .filter((entry) => (projectId ? entry.project.id === projectId : true));
    return filtered;
  },
  timetrackingEntriesForUserIdAndDifferentProjectId: (state) => (userId: number, projectId: number | undefined) => {
    const filtered = state.timetrackingEntries
      .filter((entry) => entry.userId === userId)
      .filter((entry) => (projectId ? entry.project.id !== projectId : false));
    return filtered;
  },
  timetrackingEntriesForUserIdAndProjectIdAndPositionId: (state) => (userId: number, projectId: number | undefined, positionId: number | undefined) => {
    const filtered = state.timetrackingEntries
      .filter((entry) => entry.userId === userId)
      .filter((entry) => (projectId ? entry.project.id === projectId : true))
      .filter((entry) => (projectId ? entry.projectPosition?.id === positionId : true));
    return filtered;
  },
  timetrackingEntriesForProjectId: (state) => (projectId: number | null) =>
    state.timetrackingEntries.filter((entry) => {
      if (projectId === null) {
        return true;
      }
      return entry.project.id === projectId;
    }),
  timetrackingEntries: (state) => [...state.timetrackingEntries],
  dateRange: (state) => ({ ...state.dateRange }),
};

export const mutations: MutationTree<TimetrackingState> = {
  SET_DATE_RANGE(state: TimetrackingState, dateRange: DateRange): void {
    state.dateRange = dateRange;
  },
  SET_TIMETRACKING_ENTRIES(state: TimetrackingState, timetrackingEntries: TimetrackingEntry[]): void {
    state.timetrackingEntries = timetrackingEntries;
  },
  ADD_TIMETRACKING_ENTRY(state: TimetrackingState, timetrackingEntry: TimetrackingEntry): void {
    state.timetrackingEntries = [...state.timetrackingEntries.filter((e) => e.id !== timetrackingEntry.id), timetrackingEntry];
  },
  REMOVE_TIMETRACKING_ENTRY(state: TimetrackingState, entryId: number): void {
    state.timetrackingEntries = [...state.timetrackingEntries.filter((entry) => entry.id !== entryId)];
  },
};

export const actions: ActionTree<TimetrackingState, RootState> = {
  async fetchTimetrackingEntries({ commit, state }, { fromDate, toDate }: DateRange) {
    if (state.dateRange.toDate === toDate && state.dateRange.fromDate === fromDate) {
      return;
    }

    try {
      const response = await axios.get<TimetrackingEntry[]>(`/timetracking?fromdate=${fromDate.toISOString()}&todate=${toDate.toISOString()}`, {
        withCredentials: true,
      });
      commit("SET_TIMETRACKING_ENTRIES", response.data);
      commit("SET_DATE_RANGE", { fromDate, toDate });
    } catch (e) {
      console.error(e);
    }
  },
  async fetchTimetrackingEntriesForUser({ commit, state }, { fromDate, toDate, user }: DateRange & { user: User }) {
    if (!user) {
      return;
    }
    if (state.dateRange.toDate === toDate && state.dateRange.fromDate === fromDate) {
      return;
    }

    try {
      const response = await axios.get<TimetrackingEntry[]>(`/timetracking/${user.id}?fromdate=${fromDate.toISOString()}&todate=${toDate.toISOString()}`, {
        withCredentials: true,
      });
      commit("SET_TIMETRACKING_ENTRIES", response.data);
      commit("SET_DATE_RANGE", { fromDate, toDate });
    } catch (e) {
      console.error(e);
    }
  },
  async saveTimetrackingEntry({ commit }, { timetrackingEntry, userId }: { timetrackingEntry: Partial<TimetrackingEntry>; userId: number }) {
    try {
      const response = await axios.post<TimetrackingEntry>("/timetracking", { timetrackingEntry, userId }, { withCredentials: true });
      commit("ADD_TIMETRACKING_ENTRY", response.data);
    } catch (e) {
      console.error(e);
    }
  },
  async deleteTimetrackingEntry({ commit }, entryId: number) {
    try {
      const response = await axios.delete(`/timetracking/${entryId}`, { withCredentials: true });
      if (response.status === 200) {
        commit("REMOVE_TIMETRACKING_ENTRY", entryId);
      }
    } catch (e) {
      console.error(e);
    }
  },
};

const timetrackingModule: Module<TimetrackingState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default timetrackingModule;
