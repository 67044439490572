<template>
  <Teleport to="#portal">
    <div>
      <transition name="modal">
        <div class="modal-mask overflow-y-auto" @click.self="handleClickOutside">
          <div class="table w-full h-full">
            <div class="modal-wrapper" @click.self="handleClickOutside">
              <div ref="modalWrapper" class="modal-container rounded overflow-hidden mx-auto bg-white w-full md:w-2/3 lg:w-1/2 xl:w-2/5" :class="$attrs.class">
                <div class="modal-header relative bg-gray-100 text-xl py-4 px-4">
                  <slot name="header" />
                  <button class="absolute top-0 text-gray-500 hover:text-gray-800 mt-3 mr-4 font-bold right-0 cursor-pointer" @click="$emit('close')">
                    <font-awesome-icon icon="times" />
                  </button>
                </div>
                <div class="modal-body px-4 pt-4">
                  <slot name="body" />
                </div>

                <div class="modal-footer py-4 px-4">
                  <slot name="footer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, Teleport } from "vue";

export default defineComponent({
  name: "Modal",
  emits: ["close"],
  inheritAttrs: false,
  props: {
    preventCloseOnClickOutside: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickOutside() {
      if (this.preventCloseOnClickOutside) {
        return;
      }
      this.$emit("close");
    },
  },
});
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
