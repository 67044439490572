<template>
  <div v-if="currentUser" v-cloak id="app">
    <Navigation class="print:hidden" :user="currentUser" />
    <div class="container mx-auto px-6 py-4 print:m-0 print:p-0">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Navigation from "../components/Navigation.vue";

const store = useStore();
const currentUser = computed(() => store.getters["user/currentUser"]);

const router = useRouter();
onMounted(() => {
  if (!currentUser.value) {
    router.push("/auth");
  }
});
</script>
