<template>
  <nav ref="navbar" class="sticky top-0 bg-white shadow fixed w-full top-0 z-20" role="navigation">
    <div class="container mx-auto p-4 flex flex-wrap items-center md:flex-no-wrap">
      <div class="mr-2 md:mr-4">
        <a href="#" rel="home">
          <!--          <img-->
          <!--            alt="Logo"-->
          <!--            class="h-6 rounded-full"-->
          <!--            src="~@/assets/logo.png"-->
          <!--          >-->
        </a>
      </div>
      <div class="ml-auto md:hidden">
        <button class="flex items-center px-3 py-2 border rounded" type="button" @click="showNav = !showNav">
          <svg class="h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div :class="{ hidden: !showNav }" class="w-full md:w-auto md:flex-grow md:flex md:items-center">
        <ul class="flex flex-wrap flex-col px-4 mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 lg:mr-8 md:border-0">
          <li v-if="hasPermission('USER_ADMIN', user)" class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/user">
              <span class="pb-1 md:pb-0 text-sm">Mitarbeiter</span>
            </router-link>
          </li>
          <li v-if="hasPermission('ABSENCE_ADMIN', user)" class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/vacation/requests">
              <span class="pb-1 md:pb-0 text-sm">Urlaubsanträge</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/vacation">
              <span class="pb-1 md:pb-0 text-sm">My Urlaub</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/timetracking/me">
              <span class="pb-1 md:pb-0 text-sm">Meine Zeiterfassung</span>
            </router-link>
          </li>
          <li v-if="hasPermission('PROJECT_ADMIN', user)" class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/projects/manage">
              <span class="pb-1 md:pb-0 text-sm">Projektverwaltung</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/timetracking/team">
              <span class="pb-1 md:pb-0 text-sm">Zeiterfassung Team</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/profiles">
              <span class="pb-1 md:pb-0 text-sm">Mitarbeiterprofile</span>
            </router-link>
          </li>
          <li v-if="hasPermission('PROJECT_ADMIN', user)" class="mr-6 my-2 md:my-0">
            <router-link class="nav-link" to="/app/planning">
              <span class="pb-1 md:pb-0 text-sm">Projektübersicht</span>
            </router-link>
          </li>
        </ul>

        <ul class="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:ml-auto md:mt-0 md:pt-0 md:border-0">
          <li>
            <Popper placement="bottom-start">
              <template #content>
                <UserMenu @settings="setShowSettings(!showSettings)" />
              </template>
              <a class="block px-4 py-1 md:p-2 lg:px-4 whitespace-nowrap" href="#" title="Profile">{{ fullName }} <font-awesome-icon icon="chevron-down" /></a>
            </Popper>
          </li>
        </ul>
      </div>
    </div>
    <UserSettingsModal v-if="showSettings" @close="setShowSettings(false)" />
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import Popper from "vue3-popper";
import UserMenu from "@/components/menu/UserMenu.vue";
import UserSettingsModal from "@/components/menu/UserSettingsModal.vue";
import { hasPermission, User } from "@/store/interfaces/user.interface";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  components: {
    Popper,
    UserMenu,
    UserSettingsModal,
  },
  setup() {
    return {
      navbar: ref<HTMLElement>(),
    };
  },
  data() {
    return {
      showNav: false,
      showSettings: false,
    };
  },
  computed: {
    fullName: function () {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
  },
  methods: {
    handleClick(e: Event) {
      if (e.target instanceof HTMLElement && !this.navbar?.contains(e.target)) {
        this.showNav = false;
      }
    },
    setShowSettings(show: boolean) {
      this.showSettings = show;
    },
    hasPermission,
  },
});
</script>

<style scoped lang="scss">
a.router-link-active {
  @apply text-indigo-600 border-indigo-600;
}

:deep(.popper) {
  width: 200px;
  padding: 0 !important;
  background: transparent !important;
  border-radius: 8px;
}

:deep(.popper:hover),
:deep(.popper #arrow::before) {
  background: transparent;
}

:deep(.popper:hover > #arrow::before) {
  background: transparent;
}

.nav-link {
  @apply block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600 whitespace-nowrap;
}
</style>
