import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "./index";
import axios from "@/common/axios";
import { Project, ProjectRole, ProjectUserRole } from "@/store/interfaces/project.interface";
import { groupBy } from "ramda";

const state = () => ({
  rolesByUser: {},
});

type ProjectUserRolesState = ReturnType<typeof state>;

const getters: GetterTree<ProjectUserRolesState, RootState> = {
  rolesByUser: (state) => {
    return state.rolesByUser;
  },
};

type SetUserRolesMutation = {
  userId: number;
  roles: ProjectUserRole[];
};
const mutations: MutationTree<ProjectUserRolesState> = {
  SET_PROJECT_USER_ROLES(state: ProjectUserRolesState, { userId, roles }: SetUserRolesMutation): void {
    state.rolesByUser[userId] = roles;
  },
};
const actions: ActionTree<ProjectUserRolesState, RootState> = {
  async fetchUserRolesForUser({ commit }, userId: number) {
    try {
      const response = await axios.get<ProjectUserRole[]>(`/project-user-roles/user/${userId}`, { withCredentials: true });
      const mutation: SetUserRolesMutation = { userId, roles: response.data };
      commit("SET_PROJECT_USER_ROLES", mutation);
    } catch (e) {
      console.error(e);
    }
  },
};

const projectUserRoleModule: Module<ProjectUserRolesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default projectUserRoleModule;
