<template>
  <button :disabled="disabled" class="py-1 px-3 h-full text-white rounded font-bold" :class="classes" v-bind="$attrs">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    buttonType: {
      type: String,
      default: "PRIMARY",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dangerous: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const buttonTypeLowerCase = this.buttonType?.toLocaleLowerCase();
      return {
        outlined: this.outlined,
        disabled: this.disabled,
        dangerous: this.dangerous,
        default: this.buttonType?.toLowerCase() === "default",
        "bg-green-400": buttonTypeLowerCase === "success",
        "bg-indigo-600": buttonTypeLowerCase === undefined || buttonTypeLowerCase === "primary",
      };
    },
  },
});
</script>

<style scoped>
:hover {
  opacity: 0.9;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled:hover {
  opacity: 0.5;
}

.outlined {
  border: 1px solid #5a67d8;
  background: white;
  color: #5a67d8;
}

.outlined:hover {
  background: #ebf4ff;
}

.dangerous {
  border: 1px solid #e33838;
  background: white;
  color: #e33838;
}

.dangerous:hover {
  background: #fff2f2;
}

.default {
  border: 1px solid grey;
  background: grey;
  color: white;
}
</style>
