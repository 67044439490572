import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "./index";
import axios from "@/common/axios";
import { Project, ProjectUserRole } from "@/store/interfaces/project.interface";
import bigDecimal from "js-big-decimal";

const state = () => ({
  projects: [] as Project[],
});

type ProjectsState = ReturnType<typeof state>;

const getters: GetterTree<ProjectsState, RootState> = {
  projects: (state) => [...state.projects].sort((a, b) => a.id - b.id) ?? [],
  projectById: (state) => (id: number) => {
    const foundProject = state.projects.find((p) => p.id === id);
    return foundProject;
  },
  currentUserRoles: (state) => state,
};

const mutations: MutationTree<ProjectsState> = {
  SET_PROJECTS(state: ProjectsState, projects: Project[]): void {
    state.projects = projects;
  },
};

export type PositionToAdd = { positionName: string; project: Partial<Project> };
export type PositionToEdit = { projectId: number; positionId: number; newName: string; targetMs: bigDecimal };
export type PositionToRemove = { positionId: number; project: Partial<Project> };
export type RoleToRemove = { roleId: number; project: Partial<Project> };
export type RoleToAdd = { roleName: string; project: Partial<Project> };

const actions: ActionTree<ProjectsState, RootState> = {
  async fetchProjects({ commit }) {
    try {
      const response = await axios.get("/timetracking/projects", { withCredentials: true });
      commit("SET_PROJECTS", response.data);
    } catch (e) {
      console.error(e);
    }
  },
  async addProject({ dispatch }, project) {
    try {
      await axios.post("/timetracking/projects", project, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
  async addRoleToProject({ dispatch }, roleToAdd: RoleToAdd) {
    const { project, roleName } = roleToAdd;
    try {
      await axios.post(`/projects/${project.id}/roles`, { roleName, projectId: project.id }, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (err) {
      console.error(err);
    }
  },
  async editPosition({ dispatch }, { projectId, positionId, ...request }: PositionToEdit) {
    const transforedRequest = { ...request, targetMs: request.targetMs.getValue() };
    try {
      await axios.put(`/projects/${projectId}/positions/${positionId}`, transforedRequest, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
  async removeRoleFromProject({ dispatch }, removePos: RoleToRemove) {
    const { project, roleId } = removePos;
    try {
      await axios.delete(`/projects/${project.id}/roles/${roleId}`, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (err) {
      console.error(err);
    }
  },
  async removePositionFromProject({ dispatch }, removePos: PositionToRemove) {
    const { project, positionId } = removePos;
    try {
      await axios.delete(`/projects/${project.id}/positions/${positionId}`, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (err) {
      console.error(err);
    }
  },
  async addPositionToProject({ dispatch }, positionToAdd: PositionToAdd) {
    const { project, positionName } = positionToAdd;
    try {
      await axios.post(`/projects/${project.id}/positions`, { positionName, projectId: project.id }, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (err) {
      console.error(err);
    }
  },
  async editProject({ dispatch }, project) {
    try {
      console.log("EDIT PROKECT", project);
      await axios.put(`/timetracking/projects/${project.id}`, project, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
  async deleteProject({ dispatch }, project) {
    try {
      await axios.delete(`/timetracking/projects/${project.id}`, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },

  async archiveProject({ dispatch }, project) {
    try {
      await axios.put(`/timetracking/projects/${project.id}/archived`, {}, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
};

const projectsModule: Module<ProjectsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default projectsModule;
