<script setup lang="ts">
import Modal from "@/components/shared/Modal.vue";
import Button from "@/components/shared/Button.vue";
import { useUpdateSettings } from "@/components/menu/useUpdateSettings";
import { timeOptions } from "./time-options";

const emits = defineEmits(["close"]);
const updateSettings = useUpdateSettings();

const save = () => {
  updateSettings.saveSettings();
  emits("close");
};

const setTimeValue = (e: Event) => {
  updateSettings.selectedHour.value = (e.target as HTMLInputElement).value;
};
</script>

<template>
  <Modal v-if="true" @close="emits('close')">
    <template #header> Settings </template>
    <template #body>
      <div class="flex flex-wrap -mx-3 mt-4">
        <div class="w-full px-3 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-1"> Bitbot</label>
          <span class="flex align-center w-100">
            Erinnere mich täglich um
            <select @change="setTimeValue($event)" class="flex w-100 uppercase text-gray-500 text-xs font-bold mb-1 bg-gray-200 p-1 mx-4">
              <option :selected="updateSettings.isSelected(option.time)" v-for="option in timeOptions" :value="option.time" v-bind:key="option.label">
                {{ option.label }}
              </option>
            </select>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <Button @click="save"> Speichern </Button>
      </div>
    </template>
  </Modal>
</template>
