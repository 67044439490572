import { onMounted, ref } from "vue";
import axios from "@/common/axios";
import { useStore } from "vuex";
import bigDecimal from "js-big-decimal";

type UserSettings = {
  userId: number;
  bitbotRemindTime: string | null;
};

export const useUpdateSettings = () => {
  const user = useStore();

  const settings = ref<UserSettings | null>(null);
  const selectedHour = ref<string | null>(null);

  const fetchSettings = async () => {
    const currentUser = user.state.user.currentUser;
    if (!currentUser) {
      return;
    }
    const response = await axios.get<UserSettings>(`/users/${currentUser?.id}/settings`, {
      withCredentials: true,
    });
    settings.value = response.data;
    selectedHour.value = response.data.bitbotRemindTime ? new bigDecimal(response.data.bitbotRemindTime).getValue() : null;
    console.log("Existing settings", settings.value);
  };

  const saveSettings = async () => {
    const currentUser = user.state.user.currentUser;
    if (!currentUser) {
      return;
    }
    const remindTimeToBigInt: string | null = selectedHour.value !== null ? new bigDecimal(selectedHour.value).getValue() : null;
    const dto: UserSettings = {
      bitbotRemindTime: remindTimeToBigInt,
      userId: currentUser.id,
    };
    const response = await axios.put<UserSettings>(`/users/${currentUser?.id}/settings`, dto, {
      withCredentials: true,
    });
  };

  onMounted(() => {
    fetchSettings();
  });

  const isSelected = (hour: number) => {
    if (!selectedHour.value) {
      return false;
    }
    const hourAsBig = new bigDecimal("" + hour).floor();
    const selected = new bigDecimal(selectedHour.value).floor();
    return hourAsBig.compareTo(selected) === 0;
  };
  return {
    settings,
    selectedHour,
    saveSettings,
    isSelected,
  };
};
