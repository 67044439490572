import "./polyfills";
import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import initializePlugins from "./plugins";
import "@/assets/css/tailwind.css";
import "v-calendar/dist/style.css";

if (import.meta.env.DEV) {
  if (import.meta.env.VITE_USE_NETWORK_MOCKS === "true") {
    console.log("Starting MSW");
    //const { worker } = await import("./mocks/browser");
    //worker.start();
  }
}

let app = createApp(App).use(store).use(router);

app = initializePlugins(app);
app.mount("#app");

console.log("Version", import.meta.env.VITE_COMMIT_ID, " with backend reachable on", import.meta.env.VITE_API_BASE_URL);
