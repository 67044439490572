export interface User {
  id: number;
  slackId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  googleId: string;
  profilePicture?: string;
  startDate?: Date;
  endDate?: Date;
  federalState?: string;
  iat?: number;
  exp?: number;
  isAchived?: boolean;
  permissions: UserPermission[];
}

export interface UserPermission {
  id: number;
  permission: string;
  userId: number;
}

type Permission = "PROJECT_ADMIN" | "ABSENCE_ADMIN" | "USER_ADMIN" | "PROFILE_ADMIN";

export const hasPermission = (p: Permission, u: User) => {
  return !!u?.permissions?.find((user) => user.permission === p);
};
