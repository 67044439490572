import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "./index";
import axios from "@/common/axios";
import { User } from "@/store/interfaces/user.interface";

const state = () => ({
  currentUser: null as User | null,
  users: [] as User[],
});

type UserState = ReturnType<typeof state>;

const getters: GetterTree<UserState, RootState> = {
  currentUser: (state) => state.currentUser,
  users: (state) => state.users,
  userById: (state) => (id) => state.users.find((user) => user.id === id),
};

const mutations: MutationTree<UserState> = {
  SET_USER(state: UserState, user: User): void {
    state.currentUser = user;
  },
  SET_USERS(state: UserState, users: User[]): void {
    state.users = users;
  },
  RESET(state: UserState): void {
    state.currentUser = null;
    state.users = [];
  },
};

const actions: ActionTree<UserState, RootState> = {
  async fetchCurrentUser({ commit }) {
    const response = await axios.get<User>(`/users/me`, { withCredentials: true });
    await commit("SET_USER", response.data);
    return response.data;
  },

  async fetchUsers({ commit }) {
    const response = await axios.get<User[]>(`/users`, { withCredentials: true });
    await commit("SET_USERS", response.data);
  },

  async importUsers({ dispatch }) {
    await axios.post(`/users/import`, {}, { withCredentials: true });

    await dispatch("fetchUsers");
  },

  async reset({ commit }) {
    await commit("RESET");
  },
};

const projectsModule: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default projectsModule;
