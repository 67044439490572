import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from "vue-router";
// import Vacation from '@/views/app/vacation/index.vue';
import AppLayout from "@/layouts/AppLayout.vue";

const Auth = () => import("@/views/auth/index.vue");
const Logout = () => import("@/views/auth/logout.vue");
const Vacation = () => import("@/views/app/vacation/index.vue");
const VacationRequests = () => import("@/views/app/vacation-requests/index.vue");
const Users = () => import("@/views/app/user/index.vue");
const User = () => import("@/views/app/user/_id.vue");
const TimetrackingMe = () => import("@/views/app/timetracking/me/index.vue");
const TimetrackingTeam = () => import("@/views/app/timetracking/team/index.vue");
const TimetrackingUser = () => import("@/views/app/timetracking/_id.vue");
const ProjectsManage = () => import("@/views/app/projects/manage/index.vue");
const ProjectDetail = () => import("@/views/app/projects/detail/index.vue");
const PlanningOverview = () => import("@/views/app/planning/overview/index.vue");
const EditProfile = () => import("@/views/app/profiles/_slug.vue");
const ProfileList = () => import("@/views/app/profiles/index.vue");

import { store } from "@/store";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    redirect: "/app/vacation",
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/app",
    name: "App Home",
    component: AppLayout,
    redirect: "/app/vacation",
    children: [
      {
        path: "vacation/requests",
        name: "Vacation Requests",
        component: VacationRequests,
      },
      {
        path: "vacation",
        name: "Vacation",
        component: Vacation,
      },
      {
        path: "user",
        name: "Users",
        component: Users,
      },
      {
        path: "user/:id",
        name: "User",
        props: true,
        component: User,
      },
      {
        path: "timetracking",
        name: "Timetracking",
        component: RouterView,
        redirect: "timetracking/me",
        children: [
          {
            path: "me",
            name: "Timetracking Me",
            component: TimetrackingMe,
            meta: {
              layout: "FullWidthLayout",
            },
          },
          {
            path: "team",
            name: "Timetracking Team",
            component: TimetrackingTeam,
            meta: {
              layout: "FullWidthLayout",
            },
          },
          {
            path: ":id",
            name: "Timetracking User",
            component: TimetrackingUser,
            meta: {
              layout: "FullWidthLayout",
            },
          },
        ],
      },
      { path: "projects/manage/:projectId", component: ProjectDetail, meta: { layout: "FullWidthLayout" } },
      {
        path: "projects/manage",
        name: "Project Management",
        component: ProjectsManage,
      },
      {
        path: "profiles",
        name: "Profile List",
        component: ProfileList,
      },
      {
        path: "profiles/:slug",
        name: "Profile",
        component: EditProfile,
      },
      {
        path: "planning",
        name: "Project Planning",
        component: PlanningOverview,
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/auth"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("accessToken") && localStorage.getItem("refreshToken");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/auth");
  } else if (loggedIn && !store.getters.currentUser) {
    await store.dispatch("user/fetchCurrentUser");
    next();
  } else {
    next();
  }
});
