import { App } from "vue";
import VCalendar from "v-calendar";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArchive,
  faBan,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPen,
  faPenSquare,
  faTimes,
  faUserSecret,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createI18n } from "vue-i18n";
import de from "@/i18n/de.json";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const initializePlugins = (app: App): App => {
  library.add(faUserSecret as any);
  library.add(faChevronLeft as any);
  library.add(faChevronDown as any);
  library.add(faChevronRight as any);
  library.add(faTimes as any);
  library.add(faCheck as any);
  library.add(faBan as any);
  library.add(faPenSquare as any);
  library.add(faPen as any);
  library.add(faArchive as any);
  library.add(faPrint as any);
  library.add(faTrash as any);

  const i18n = createI18n({
    //globalInjection: true,
    //legacy: false, // we used this for english profiles. we need to elaborate why this was needed. This breaks vacation view
    locale: "de",
    fallbackLocale: "de",
    messages: {
      de: de,
    },
    datetimeFormats: {
      de: {
        date: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        },
        year: {
          year: "numeric",
        },
        monthDay: {
          month: "2-digit",
          day: "2-digit",
        },
        monthShort: {
          month: "short",
        },
        dateWeekdayPrefix: {
          weekday: "long",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        },
        dateTime: {
          weekday: "short",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
      },
    },
  });

  return app
    .use(i18n)
    .use(Toast)
    .use(VCalendar, {
      datePickerShowDayPopover: false,
      locale: "de",
    })
    .component("font-awesome-icon", FontAwesomeIcon);
};

export default initializePlugins;
