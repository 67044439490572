import axios from "axios";
import { router } from "@/router";

const axiosInstance = () => {
  const defaultOptions = {
    baseURL: import.meta.env.VITE_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  function getNewToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      instance
        .post("/auth/refresh", { refreshToken: localStorage.getItem("refreshToken") })
        .then((response) => {
          if (response?.data?.accessToken && response?.data?.refreshToken) {
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);

            resolve(response.data.accessToken);
          } else {
            reject("Could not get token");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Return any error which is not due to authentication back to the calling service
      if (error.response?.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work or user is disabled
      if (error.config.url == "/auth/refresh" || !localStorage.getItem("refreshToken") /*|| error.response.message == "Account is disabled."*/) {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        router.push("/auth");

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Try request again with new token
      return getNewToken()
        .then((accessToken) => {
          // New request with new token
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${accessToken}`;

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          Promise.reject(error);
        });
    }
  );

  return instance;
};

export default axiosInstance();
