import { createStore, Store } from "vuex";
import timetracking from "./timetracking";
import projects from "./projects";
import projectUserRoles from "./project-user-roles";
import absence from "./absence";
import user from "./user";
import { InjectionKey } from "vue";

const state = {};
export type RootState = typeof state;
export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  strict: true,
  devtools: true,
  state,
  modules: {
    absence,
    projects,
    timetracking,
    user,
    projectUserRoles,
  },
});
